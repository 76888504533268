@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-cyrillic-ext-400-normal.19f3c92d.woff2") format("woff2"), url("inter-cyrillic-ext-400-normal.abb21bb3.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-cyrillic-400-normal.1ac9e9df.woff2") format("woff2"), url("inter-cyrillic-400-normal.f82fad09.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-greek-ext-400-normal.f95a7688.woff2") format("woff2"), url("inter-greek-ext-400-normal.bdbd463f.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-greek-400-normal.e62a5fd0.woff2") format("woff2"), url("inter-greek-400-normal.c3f120ef.woff") format("woff");
  unicode-range: U+370-377, U+37A-37F, U+384-38A, U+38C, U+38E-3A1, U+3A3-3FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-vietnamese-400-normal.7943b7a7.woff2") format("woff2"), url("inter-vietnamese-400-normal.c66738c8.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-latin-ext-400-normal.a9e35bf7.woff2") format("woff2"), url("inter-latin-ext-400-normal.e68cb43c.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-latin-400-normal.8f7fd453.woff2") format("woff2"), url("inter-latin-400-normal.79cb8b9d.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*# sourceMappingURL=index.8f051d25.css.map */
